import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { getToken } from "./request/auth"

import { API_URI } from "../constants"

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URI,
  // mode: "cors",
  prepareHeaders: (headers) => {
    headers.set("Authorization", getToken() || "")
    headers.set("Content-Type", "application/json")
    return headers
  },
})
