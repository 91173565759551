import axios, { AxiosError, AxiosInstance } from "axios"

import { authApi } from "api/auth"
import { mediasApi } from "api/medias"
import { transformResponse } from "api/utils"
import { notify } from "components/Notification/Notification"
import { dispatch } from "services/store"

import { getToken, setToken } from "./auth"

import { API_URI } from "../../constants"

export class ApiRequest {
  api: AxiosInstance
  dispatch: typeof dispatch

  constructor() {
    this.dispatch = dispatch
    this.api = axios.create({
      baseURL: API_URI,
      headers: { Authorization: getToken(), accept: "application/json" },
    })

    this.api.interceptors.response.use(
      (response) => {
        if (response?.headers?.["authorization"]) {
          setToken(response.headers["authorization"])
          this.dispatch(authApi.actions.setToken(response.headers["authorization"]))
        }
        return transformResponse(response.data.data)
      },
      (error: AxiosError<any, any>) => {
        if (error.status === 401) {
          setToken(null)
          this.dispatch(authApi.actions.logout())
          this.dispatch(mediasApi.actions.clear())
        }

        const errorHeader = `[${error.status}] ${error.request.statusText}`
        const errors: { title: string; detail: string; code: number }[] =
          error.response?.data?.errors

        let graindedError = ""

        if (errors) {
          graindedError = errors.map((e) => e.detail).join("\n")
        }

        notify.error(graindedError, errorHeader, 3000)

        return Promise.reject(error)
      },
    )

    this.api.interceptors.request.use((request) => {
      request.headers["Authorization"] = getToken()
      return request
    }, Promise.reject)
  }
}
