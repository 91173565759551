import { useState } from "react"
import { useSelector } from "react-redux"
import { Button, DatePicker, Form, Input, TagInput } from "rsuite"

import api from "api"
import { ModalAction } from "components/Modal/ModalAction/ModalAction"
import { RootState } from "services/store"
import { Invitation } from "types/Invitation"

type InvitationFormProps = {
  style?: React.CSSProperties
  className?: string
}

export function InvitationForm(props: InvitationFormProps) {
  const { style, className } = props
  const [open, setOpen] = useState(false)

  const me = useSelector((state: RootState) => state.auth.me)

  const [createInvitation] = api.invitations.create()

  const [invitationEdit, setInvitationEdit] = useState<Partial<Invitation>>()

  function onExited() {
    setInvitationEdit(undefined)
  }

  function onClose() {
    setOpen(false)
  }

  function onCancel() {
    setOpen(false)
    return Promise.resolve()
  }

  async function onValidate() {
    const newInvitation = {
      ...invitationEdit,
      invitedBy: me?.email,
    }

    await createInvitation({
      data: newInvitation,
    }).unwrap()

    return onClose()
  }

  return (
    <>
      <ModalAction
        open={open}
        onClose={onClose}
        onValidate={onValidate}
        onCancel={onCancel}
        onExited={onExited}
        title="Créer une invitation"
        cancelLabel={"Cancel"}
      >
        <Form fluid onChange={setInvitationEdit} formValue={invitationEdit}>
          <Form.Group controlId="expireOn">
            <Form.ControlLabel>Date d'expiration</Form.ControlLabel>
            <Form.Control name="expireOn" accepter={DatePicker} />
            <Form.HelpText>[Optionnel] Date d'expiration de l'invitation</Form.HelpText>
          </Form.Group>
          <Form.Group controlId="maxUses">
            <Form.ControlLabel>Nombre d'utilisation</Form.ControlLabel>
            <Form.Control
              name="maxUses"
              accepter={Input}
              type="number"
              min={0}
              max={100}
              value={invitationEdit?.maxUses || 0}
            />
            <Form.HelpText>
              [Optionnel] Nombre maximum d'utilisation de l'invitation (0 pour illimité)
            </Form.HelpText>
          </Form.Group>
          <Form.Group controlId="emailsToInvite">
            <Form.ControlLabel>Restriction par e-mails</Form.ControlLabel>
            <Form.Control name="emailsToInvite" accepter={TagInput} style={{ width: "100%" }} />
            <Form.HelpText>
              [Optionnel] E-mails des utilisateurs autorisés pour cette invitation
            </Form.HelpText>
          </Form.Group>
        </Form>
      </ModalAction>
      <Button
        style={style}
        className={className}
        appearance="primary"
        onClick={() => setOpen(true)}
      >
        Créer une invitation
      </Button>
    </>
  )
}
