import { Outlet } from "react-router-dom"

import { RoleType } from "roles"

import { RoleRestrict } from "./RoleRestrict"

type PageRestrictProps = {
  role?: number
}

export function PageRestrict(props: PageRestrictProps) {
  const { role = RoleType.ADMIN } = props

  return (
    <RoleRestrict role={role}>
      <Outlet />
    </RoleRestrict>
  )
}
