import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { User } from "types/User"

import { getToken } from "./request/auth"

export type AuthState = {
  me: User | null
  token: string | null
}

const initialState = {
  me: null,
  token: getToken(),
} as AuthState

export const authApi = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload
    },
    setMe: (state, action: PayloadAction<User | null>) => {
      state.me = action.payload
    },
    logout: (state) => {
      state.me = null
      state.token = null
    },
  },
})

export default authApi.reducer
