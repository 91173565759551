export const API_URI = process.env.REACT_APP_API_URI as string
export const WS_URL = process.env.REACT_APP_WS_URL as string
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN as string

export const PLEX_URI = process.env.REACT_APP_PLEX_URI as string
export const PLEX_TOKEN = process.env.REACT_APP_PLEX_TOKEN as string
export const PLEX_CLIENT_IDENTIFIER = process.env.REACT_APP_PLEX_CLIENT_IDENTIFIER as string
export const PLEX_ADMIN_EMAIL = process.env.REACT_APP_PLEX_ADMIN_EMAIL as string

export const RADARR_URI = process.env.REACT_APP_RADARR_URI as string
export const SONARR_URI = process.env.REACT_APP_SONARR_URI as string
export const FILES_URI = process.env.REACT_APP_FILES_URI as string
export const JACKETT_URI = process.env.REACT_APP_JACKETT_URI as string
export const DOWNLOAD_URI = process.env.REACT_APP_DOWNLOAD_URI as string
export const FLARESOLVER_URI = process.env.REACT_APP_FLARESOLVER_URI as string
