import { Invitation } from "types/Invitation"
import { User } from "types/User"

import { ApiRequest } from "./request"

export class Invitations extends ApiRequest {
  getRedirectUrl() {
    return this.api.get<any, string>("/invitations/redirectUrl")
  }

  testInvitationCode(code: string) {
    return this.api.get<any, { valid: boolean; invitation: Invitation }>(`/invitations/${code}`)
  }

  acceptInvitation(code: string, plexToken: string) {
    return this.api.post<any, User>("/invitations/accept", {
      code,
      plexToken,
    })
  }
}
