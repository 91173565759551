import classes from "./Page.module.scss"

type PageProps = {
  children: React.ReactNode
}

export function Page(props: PageProps) {
  const { children } = props

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>{children}</div>
    </div>
  )
}
