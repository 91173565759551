import { createHashRouter, RouterProvider } from "react-router-dom"

import { PageRestrict } from "components/Restrictions/PageRestrict"
import { AppsContextProvider } from "contexts/AppsContext"
import { DownloadContextProvider } from "contexts/DownloadContext"
import { IframeContextProvider } from "contexts/IframeContext"
import { ServerContextProvider } from "contexts/ServerContext"
import { useErrorsHandling } from "hooks/useErrorsHandling"
import { RoleType } from "roles"
import { Error404 } from "views/Errors/Error404"
import { Interface } from "views/Interface"
import { InvitationInformations } from "views/Invitations/InvitationInformations"
import { Invitations } from "views/Invitations/Invitations"
import { Login } from "views/Login"
import { Requests } from "views/Requests/Requests"
import { Search } from "views/Search/Search"
import { Users } from "views/Users/Users"

import "styles/index.scss"

export function App() {
  useErrorsHandling()

  const router = createHashRouter([
    {
      path: "/",
      element: (
        <AppsContextProvider>
          <ServerContextProvider>
            <DownloadContextProvider>
              <IframeContextProvider>
                <Interface />
              </IframeContextProvider>
            </DownloadContextProvider>
          </ServerContextProvider>
        </AppsContextProvider>
      ),
      errorElement: <Error404 />,
      children: [
        {
          path: "requests",
          element: <Requests />,
        },
        {
          path: "search",
          element: <Search />,
        },
        {
          path: "admin",
          element: <PageRestrict role={RoleType.ADMIN} />,
          children: [
            {
              path: "invitations",
              element: <Invitations />,
            },
            {
              path: "users",
              element: <Users />,
            },
          ],
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
      errorElement: <Error404 />,
    },
    {
      path: "invitation/:code",
      element: <InvitationInformations />,
      errorElement: <Error404 />,
    },
  ])

  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  )
}
