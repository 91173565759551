import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Pagination as RSPagination, Placeholder } from "rsuite"

import { Button } from "components/Button/Button"
import { Media as MediaComponent } from "components/Media/Media"
import { RootState } from "services/store"
import { MultiRes, Pagination } from "types/API"
import { Media, MediaType } from "types/Content"

import classes from "./MediaList.module.scss"

type MediaListProps<T extends NonNullable<MediaType>, O extends MultiRes<Media<T>>> = {
  request: (params: { type: T } & Pagination) => Promise<O>
  type: T
  title: string
}

export function MediaList<T extends NonNullable<MediaType>, O extends MultiRes<Media<T>>>(
  props: MediaListProps<T, O>,
) {
  const { type, title, request } = props

  const [page, onChangePage] = useState(1)
  const [response, setResponse] = useState<MultiRes<Media<T>>>()
  const [isFetching, setIsFetching] = useState(false)

  const listRef = useRef<HTMLDivElement>(null)

  const queries = useMemo(() => ({ type, page }), [type, page])

  const fetch = useCallback(async () => {
    setIsFetching(true)
    const response = await request(queries)
    setResponse(response)
    setIsFetching(false)
  }, [queries])

  useEffect(() => void fetch(), [queries, fetch])

  useEffect(() => setResponse(undefined), [type])

  const medias = useSelector((state: RootState) => state.medias.medias)

  const width = listRef.current?.parentElement?.clientWidth || 0
  const contentWidth = 210
  const scrollUnit = Math.floor(width / contentWidth) * contentWidth

  function scrollNext() {
    if (isFetching) return

    const { scrollWidth = 1, scrollLeft = 2, clientWidth = 3 } = listRef.current || {}

    if (scrollWidth <= scrollLeft + clientWidth && page < (response?.pages || 0)) {
      onChangePage(page + 1)
      listRef.current?.scrollTo({ left: 0, behavior: "smooth" })
      return
    }

    listRef.current?.scrollTo({ left: scrollLeft + scrollUnit, behavior: "smooth" })
  }

  function scrollPrev() {
    if (isFetching) return

    const { scrollLeft = 10, scrollWidth } = listRef.current || {}
    if (scrollLeft === 0 && page > 1) {
      onChangePage(page - 1)
      listRef.current?.scrollTo({ left: scrollWidth || 10000, behavior: "smooth" })
      return
    }

    listRef.current?.scrollBy({ left: -scrollUnit, behavior: "smooth" })
  }

  return (
    <div className={classes.container}>
      <div className="fullWidth flex alignCenter spaceBetween">
        <h4>{title}</h4>

        <div className="fullHeight flex alignCenter">
          <Button
            appearance="subtle"
            icon="chevron-left"
            onClick={scrollPrev}
            className="marginRight10"
          />
          <Button appearance="subtle" icon="chevron-right" onClick={scrollNext} />
        </div>
      </div>
      <div className={classes.mediasContainer} ref={listRef}>
        <div className={classes.medias}>
          {(response?.results.length
            ? response.results.map((item) => medias[item.tmdbId])
            : Array(20).fill({})
          ).map((media, index) => {
            if (isFetching || !media) {
              return <Placeholder.Graph key={index} active className={classes.mediaPlaceholder} />
            }
            return <MediaComponent key={media?.id || index} media={media} />
          })}
        </div>
      </div>
      <RSPagination
        style={{ alignSelf: "end" }}
        layout={["pager"]}
        prev
        next
        ellipsis
        total={response?.totalCount || 0}
        maxButtons={3}
        activePage={page}
        onChangePage={(page) => {
          onChangePage(page)
          listRef.current?.scrollTo({ left: 0, behavior: "smooth" })
        }}
      />
    </div>
  )
}
