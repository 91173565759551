import { forwardRef, ReactNode, useContext, useRef } from "react"
import { Badge, Popover, PopoverProps, Whisper } from "rsuite"
import { OverlayTriggerHandle } from "rsuite/esm/internals/Picker"

import { IconButton } from "components/Button/IconButton"
import { CellContent } from "components/Content/CellContent"
import { DownloadContext } from "contexts/DownloadContext"

import classes from "./Downloads.module.scss"

export const Poppover = forwardRef(
  ({ content, ...props }: { content: ReactNode } & PopoverProps, ref) => {
    return (
      <Popover ref={ref} {...props}>
        {content}
      </Popover>
    )
  },
)

export function Downloads() {
  const { downloads = {} } = useContext(DownloadContext)

  const whisperRef = useRef<OverlayTriggerHandle | null>()

  const downloadsContent = Object.values(downloads).map((d) => (
    <div
      key={d.tmdbId}
      style={{ maxWidth: 500, width: 500, overflow: "hidden", position: "relative" }}
      onMouseUp={() => whisperRef.current?.close()}
    >
      {CellContent(d, "compact")}
    </div>
  ))

  if (!downloadsContent.length) return null

  return (
    <div className={classes.container}>
      <Whisper
        trigger="click"
        placement="bottomEnd"
        controlId="downloads-control-id"
        speaker={<Poppover content={downloadsContent} />}
        ref={(ref) => {
          whisperRef.current = ref
        }}
      >
        <Badge content={downloadsContent.length}>
          <IconButton tooltip="Téléchargements en cours" icon="download" appearance="subtle" />
        </Badge>
      </Whisper>
    </div>
  )
}
