import { Auth } from "./auth"
import { Invitations } from "./invitations"
import { Medias } from "./medias"
import { Server } from "./server"

export default {
  medias: new Medias(),
  auth: new Auth(),
  invitations: new Invitations(),
  server: new Server(),
}
