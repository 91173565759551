import moment from "moment"
import { useContext } from "react"
import { Rate } from "rsuite"

import { FontIcon } from "components/FontIcon/FontIcon"
import { TMDBImage } from "components/TMDBImage/TMDBImage"
import { DownloadContext } from "contexts/DownloadContext"
import { Content } from "types/Content"

import classes from "../Content.module.scss"
import { ImportButton } from "../ImportButton/ImportButton"

export function Serie(props: { serie: Content<"serie">; format?: "compact" | "full" }) {
  const { serie, format } = props
  const { name, poster_path, first_air_date, overview, vote_average } = serie.tmdbMedia || {}

  const { openMediaDetail } = useContext(DownloadContext)

  return (
    <div className={classes.container} media-format={format} onClick={() => openMediaDetail(serie)}>
      <div className={classes.image} media-format={format}>
        <TMDBImage src={poster_path} type="poster" size="w185" />
      </div>
      <div className={classes.infos} media-format={format}>
        <div className={classes.title} media-format={format}>
          <FontIcon icon="tv" className="marginRight10" />
          <div className={classes.titleText} media-format={format}>
            {name}
          </div>
          {first_air_date ? (
            <div className={classes.year}>{moment(first_air_date).format("YYYY")}</div>
          ) : null}
          <Rate
            className={classes.rate}
            defaultValue={Math.round((vote_average / 2) * 2) / 2}
            readOnly
            size="xs"
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              right: format === "compact" ? 0 : 30,
            }}
          >
            <ImportButton media={serie} format={format} />
          </div>
        </div>
        <div className={classes.summary} media-format={format}>
          {overview}
        </div>
      </div>
    </div>
  )
}
