import { ReactNode } from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

import { RootState } from "services/store"

type AuthRestrictProps = {
  children?: ReactNode
  role?: number
}

export function AuthRestrict(props: AuthRestrictProps) {
  const { children } = props

  const me = useSelector((state: RootState) => state.auth.me)

  const { pathname } = useLocation()

  if (!me) {
    return <Navigate to={`/login${pathname !== "/" ? `?return=${pathname}` : ""}`} replace />
  }

  return children
}
