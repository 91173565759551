import { useState } from "react"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { Button, Input, InputGroup, Loader } from "rsuite"

import request from "api/request"
import { FontIcon } from "components/FontIcon/FontIcon"
import { useAuthenticate } from "hooks/useAuthenticate"

export function Login() {
  const { me, authenticate, isLogging, isAuthenticate } = useAuthenticate()

  const [invitationCode, setInvitationCode] = useState("")

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  async function validateCode() {
    try {
      await request.invitations.testInvitationCode(invitationCode)
      navigate(`/invitation/${invitationCode}`)
    } catch (error) {
      console.error(error)
    }
  }

  if (me) {
    const returnPath = searchParams.get("return")
    return <Navigate to={returnPath || "/"} replace />
  }

  if (isLogging) {
    return (
      <div className="fullWidth fullHeight flex center alignCenter">
        <Loader />
      </div>
    )
  }

  return (
    <div className="relative fullWidth fullHeight flex center alignCenter">
      <div className="flex column center alignCenter">
        <div className="fontSize30">
          Ponpon<span style={{ color: "#e5a00c" }}>.tv</span>
        </div>

        <Button
          loading={isAuthenticate}
          style={{ width: 200, marginTop: 20 }}
          onClick={async () => {
            await authenticate()
          }}
          appearance="primary"
        >
          Connection
        </Button>

        <InputGroup inside style={{ width: 200, marginTop: 10 }}>
          <Input
            value={invitationCode}
            onChange={setInvitationCode}
            placeholder="Code d'invitation"
            maxLength={6}
            minLength={6}
          />
          <InputGroup.Button
            style={{ backgroundColor: "#c48b06", marginRight: -1 }}
            onClick={validateCode}
          >
            <FontIcon icon="circle-right" />
          </InputGroup.Button>
        </InputGroup>
      </div>
    </div>
  )
}
