import Cookies from "js-cookie"

import { authApi } from "api/auth"
import { mediasApi } from "api/medias"
import { User } from "types/User"

import { ApiRequest } from "./request"

export function getToken() {
  return Cookies.get("access_token")
}

export function setToken(token: string | null) {
  const domain = location.origin.includes("localhost") ? "localhost" : `.ponpon.tv`

  if (token === null) {
    Cookies.remove("access_token", { domain })
    localStorage.clear()
  } else {
    Cookies.set("access_token", token, { expires: 7, domain })
  }
}

export class Auth extends ApiRequest {
  async login(plexToken: string): Promise<string> {
    const token = await this.api.post<any, string>("/login", { plexToken })
    if (token) setToken(token)
    return token
  }
  async logout() {
    setToken(null)
    this.dispatch(authApi.actions.logout())
    this.dispatch(mediasApi.actions.clear())
  }
  async getMe(): Promise<User> {
    const me = await this.api.get<any, User>("/users/me")
    this.dispatch(authApi.actions.setMe(me))
    return me
  }
}
