import { Dispatch, SetStateAction, useState } from "react"
import { useSelector } from "react-redux"
import { Navigate, useNavigate, useParams } from "react-router-dom"

import request from "api/request"
import { Button } from "components/Button/Button"
import { Stepper } from "components/Stepper/Stepper"
import { RootState } from "services/store"
import { User } from "types/User"
import { plexAuth } from "views/PlexAuth/authUtils"

import { PLEX_ADMIN_EMAIL } from "../../constants"

import classes from "./InvitationInformations.module.scss"

export function InvitationInformations() {
  const { code } = useParams()
  const navigate = useNavigate()

  const me = useSelector((state: RootState) => state.auth.me)

  const [isAuthenticate, setIsAuthenticate] = useState(false)
  const [user, setUser] = useState<User | null>(null)

  async function signin(setIndex: Dispatch<SetStateAction<number>>) {
    try {
      setIsAuthenticate(true)
      const plexAuthToken = await plexAuth(() => setIsAuthenticate(false))
      setUser(await request.invitations.acceptInvitation(code as string, plexAuthToken))
      setIndex((step) => step + 1)
    } catch (error) {
      setIsAuthenticate(false)
    }
  }

  async function login() {
    if (user?.plexToken) {
      await request.auth.login(user.plexToken)
      await request.auth.getMe()
      navigate("/")
    } else {
      navigate("/login")
    }
  }

  if (me) {
    return <Navigate to={"/"} replace />
  }

  if (!code) {
    return <Navigate to="/login" replace />
  }

  return (
    <Stepper
      steps={[
        {
          title: "Informations",
          subtitle: "Informations sur l'utilisation de Plex",
          content: (
            <div className={classes.content}>
              <h2>
                Règles du serveur Ponpon<span style={{ color: "#e5a00c" }}>.tv</span>
              </h2>
              <p className="rootPadding">
                En tant qu'utilisateur, je reconnais et m'engage à respecter les points suivants :
              </p>
              <h3>Comprendre les règles</h3>
              <p>
                J'ai lu et compris les règles et directives fournies par l'administrateur du
                serveur.
              </p>
              <p>
                Je demanderai des éclaircissements si une règle ou une directive n'est pas claire
                pour moi à l'adresse <span className="bold">{PLEX_ADMIN_EMAIL}</span>.
              </p>
              <h3>Respecter les directives</h3>
              <p>Je ne téléchargerai ni ne demanderai de contenu inapproprié sur le serveur.</p>
              <h3>Confidentialité et sécurité</h3>
              <p>
                Je protégerai les détails de mon compte et ne les partagerai avec personne d'autre.
              </p>
              <p>
                Je respecterai la vie privée des autres utilisateurs et ne partagerai pas leurs
                informations personnelles sans consentement.
              </p>
              <h3>Utilisation du contenu</h3>
              <p>
                Je respecterai les directives de contenu de Ponpon
                <span style={{ color: "#e5a00c" }}>.tv</span>, y compris toutes restrictions sur le
                partage ou l'accès à certains types de contenu.
              </p>
              <h3>Utilisation des ressources</h3>
              <p>
                Je serai attentif à mon utilisation des ressources de Ponpon
                <span style={{ color: "#e5a00c" }}>.tv</span>, telles que la bande passante et le
                stockage, pour garantir un accès équitable à tous les utilisateurs.
              </p>
              <h3>Conformité aux mises à jour et à la maintenance</h3>
              <p>
                Je me conformerai à tout calendrier de maintenance de Ponpon
                <span style={{ color: "#e5a00c" }}>.tv</span> et m'adapterai aux changements ou
                mises à jour nécessaires.
              </p>
              <h3>Signaler les problèmes</h3>
              <p className="rootPadding">
                En continuant à utiliser ce serveur Plex, je m'engage à respecter ces règles et je
                comprends que le non-respect peut entraîner la révocation de mon accès.
              </p>
            </div>
          ),
          prevButton: () => (
            <Button style={{ height: 25 }} onClick={() => navigate("/login")}>
              Retour à la page de connexion
            </Button>
          ),
        },
        {
          title: "Inscription",
          subtitle: "Créer un compte Plex",
          nextButton: ({ setIndex }) => (
            <Button
              style={{ height: 25 }}
              appearance="primary"
              disabled={isAuthenticate}
              loading={isAuthenticate}
              onClick={() => signin(setIndex)}
            >
              S'inscrire
            </Button>
          ),
          prevButton: ({ setIndex, index }) => (
            <div className="flex row">
              <Button
                appearance="primary"
                style={{ height: 25, marginRight: 10 }}
                disabled={index === 0}
                onClick={() => setIndex(index - 1)}
              >
                Retour
              </Button>
              <Button style={{ height: 25 }} onClick={() => navigate("/login")}>
                Retour à la page de connexion
              </Button>
            </div>
          ),
          content: (
            <div className={classes.content}>
              <h2>Informations sur les types d'inscription</h2>
              <h3>1. Inscription par adresse e-mail</h3>
              <p>
                <span className="bold">Comment ça marche ?</span> Vous fournissez une adresse e-mail
                valide et créez un mot de passe. Vous devrez utiliser cette adresse e-mail et ce mot
                de passe chaque fois que vous vous connecterez à votre compte Plex.
              </p>
              <p>
                <span className="bold">Avantages : </span> Cette méthode vous permet de garder votre
                compte Plex complètement indépendant des autres services en ligne. Cela peut être
                une bonne option si vous préférez ne pas lier vos comptes de réseaux sociaux aux
                services de streaming.
              </p>
              <p>
                <span className="bold">Inconvénients : </span> Vous devez vous souvenir d'un autre
                mot de passe. Si vous utilisez plusieurs adresses e-mail, vous devrez vous souvenir
                de celle que vous avez utilisée pour vous inscrire à Plex.
              </p>
              <h3>2. Inscription via Google</h3>
              <p>
                <span className="bold">Comment ça marche ?</span> Vous utilisez votre compte Google
                existant pour vous inscrire. Plex récupérera les informations de base de votre
                profil Google pour créer votre compte.
              </p>
              <p>
                <span className="bold">Avantages : </span> Si vous utilisez déjà Google pour
                d'autres services, cette méthode peut simplifier votre processus de connexion en
                vous permettant d'utiliser les mêmes identifiants. Elle peut également accélérer le
                processus d'inscription, car certaines informations seront automatiquement remplies.
              </p>
              <p>
                <span className="bold">Inconvénients : </span> Votre compte Plex sera lié à votre
                compte Google. Si votre compte Google est compromis, votre compte Plex pourrait
                l'être aussi.
              </p>
              <h3>3. Inscription via Facebook</h3>
              <p>
                <span className="bold">Comment ça marche ?</span> Comme pour l'inscription via
                Google, vous utilisez votre compte Facebook existant pour créer un compte Plex. Plex
                utilisera certaines de vos informations Facebook pour configurer votre compte.
              </p>
              <p>
                <span className="bold">Avantages : </span> Cette méthode est pratique si vous êtes
                souvent connecté à Facebook et préférez utiliser vos identifiants Facebook pour
                accéder à divers services.
              </p>
              <p>
                <span className="bold">Inconvénients : </span> Comme avec Google, lier votre compte
                Plex à votre compte Facebook signifie que la sécurité de votre compte Plex est
                directement liée à celle de votre compte Facebook.
              </p>
              <h3>4. Inscription via Apple</h3>
              <p>
                <span className="bold">Comment ça marche ?</span> Si vous possédez un appareil Apple
                ou utilisez un identifiant Apple, vous pouvez vous inscrire à Plex en utilisant
                votre identifiant Apple. Cette méthode fonctionne bien pour ceux qui font partie de
                l'écosystème Apple.
              </p>
              <p>
                <span className="bold">Avantages : </span> Utiliser un identifiant Apple pour
                l'inscription peut être plus sécurisé grâce à la fonctionnalité de mot de passe à
                usage unique et à la vérification en deux étapes offertes par Apple. De plus, vous
                pouvez choisir de masquer votre véritable adresse e-mail à Plex, améliorant ainsi
                votre confidentialité.
              </p>
              <p>
                <span className="bold">Inconvénients : </span> Si vous n'utilisez pas régulièrement
                les produits ou services Apple, cette option peut être moins pertinente pour vous.
                De plus, si vous perdez l'accès à votre compte Apple, vous pourriez également avoir
                du mal à accéder à votre compte Plex.
              </p>
              <p className="rootPadding">
                Chaque méthode d'inscription a ses avantages et ses inconvénients, et la meilleure
                option dépend de vos préférences personnelles et de votre utilisation d'autres
                services en ligne. Il est important de choisir une méthode qui correspond à vos
                habitudes en ligne ainsi qu'à vos préoccupations en matière de sécurité et de
                confidentialité.
              </p>
            </div>
          ),
        },
        {
          title: "Bienvenue !",
          subtitle: "Bienvenue sur Ponpon.tv",
          nextButton: () => (
            <Button style={{ height: 25 }} appearance="primary" onClick={login}>
              C'est parti !
            </Button>
          ),
          prevButton: () => <div />,
          content: (
            <div className={classes.content}>
              <h2>
                Bienvenue sur Ponpon
                <span style={{ color: "#e5a00c" }}>.tv</span> !
              </h2>
              <p>
                Bonjour et bienvenue ! Nous sommes ravis de vous avoir parmi nous. Ponpon
                <span style={{ color: "#e5a00c" }}>.tv</span> est un endroit où nous partageons et
                apprécions une fantastique collection de films et séries TV. Nous espérons que vous
                trouverez quelque chose que vous aimez et que vous apprécierez votre temps ici.
              </p>
              <p>
                Veuillez vous rappeler de respecter les autres et le contenu partagé sur ce serveur.
              </p>
              <p>
                Si vous avez des questions ou avez besoin d'aide, n'hésitez pas à nous contacter à
                l'adresse {PLEX_ADMIN_EMAIL}. Nous sommes là pour vous aider !
              </p>
              <p>Bon visionnage,</p>
              <p>
                <span className="bold">
                  Ponpon<span style={{ color: "#e5a00c" }}>.tv</span>
                </span>
              </p>
            </div>
          ),
        },
      ]}
    />
  )
}
