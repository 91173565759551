import { createContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

import { PLEX_URI } from "../constants"

export type IframeContextType = {
  iframeNavigate: (url: string) => void
  iframeUrl: string
  getRef: (ref: HTMLIFrameElement | null) => void
  iframeIsLoaded: boolean
}

export const IframeContext = createContext<IframeContextType>({
  iframeNavigate: () => undefined,
  iframeUrl: PLEX_URI,
  getRef: () => undefined,
  iframeIsLoaded: false,
})

export function IframeContextProvider(props: { children: React.ReactNode }) {
  const { children } = props

  const navigate = useNavigate()

  const [iframeUrl] = useState<string>(PLEX_URI)
  const [iframeIsLoaded, setIframeIsLoaded] = useState(false)

  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  function iframeLoaded() {
    setIframeIsLoaded(true)
  }

  function iframeUnloaded() {
    setIframeIsLoaded(false)
  }

  function iframeNavigate(url: string) {
    if (iframeRef.current) {
      navigate("/")
      iframeRef.current.src = `${PLEX_URI}${url}`
    }
  }

  const getRef = (ref: HTMLIFrameElement | null) => {
    iframeRef.current = ref
  }

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.addEventListener("load", iframeLoaded)
      iframeRef.current.addEventListener("unload", iframeUnloaded)
    }
    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener("load", iframeLoaded)
        iframeRef.current.removeEventListener("unload", iframeUnloaded)
      }
    }
  }, [!!iframeRef.current])

  return (
    <IframeContext.Provider value={{ iframeNavigate, iframeUrl, getRef, iframeIsLoaded }}>
      {children}
    </IframeContext.Provider>
  )
}
