import { IconName } from "@fortawesome/fontawesome-svg-core"
import { CSSProperties, MouseEvent, MouseEventHandler, useContext, useMemo, useState } from "react"
import { ButtonGroup } from "rsuite"

import request from "api/request"
import { Button } from "components/Button/Button"
import { ContextMenu, ContextMenuOption } from "components/ContextMenu/ContextMenu"
import { DownloadProgress } from "components/DownloadProgress/DownloadProgress"
import { notify } from "components/Notification/Notification"
import { AppsContext } from "contexts/AppsContext"
import { useMe } from "hooks/useMe"
import { RoleType } from "roles"
import { Media, MediaType } from "types/Content"

import classes from "./ImportButton.module.scss"

export function ImportButton<T extends MediaType>(props: {
  media: Media<T>
  format?: "full" | "compact"
  style?: CSSProperties
}) {
  const { media, style, format = "full" } = props
  const { status, downloadStatus, lastRequestedAt, type } = media || {}

  const { role } = useMe()

  const { appsNavigate } = useContext(AppsContext)

  const [isImporting, setIsImporting] = useState(false)

  const filePath = media.type === "movie" ? media.media.folderName : media.media.path

  function preventDispatch(e: MouseEvent) {
    e.nativeEvent.stopImmediatePropagation()
    e.nativeEvent.stopPropagation()
    e.stopPropagation()
    e.preventDefault()
  }

  async function onImport(e: MouseEvent) {
    preventDispatch(e)
    setIsImporting(true)
    try {
      await request.medias.import(media)
    } catch (error) {
      console.error(error)
    }
    setIsImporting(false)
  }

  async function onRestartImport(e: MouseEvent) {
    preventDispatch(e)
    setIsImporting(true)
    try {
      await request.medias.restartImport(media)
    } catch (error) {
      console.error(error)
    }
    setIsImporting(false)
  }

  function openApp(e: MouseEvent) {
    preventDispatch(e)
    appsNavigate(type === "movie" ? "radarr" : "sonarr", "/activity/queue")
  }

  function openFiles(e: MouseEvent) {
    preventDispatch(e)
    appsNavigate("files", encodeURI(filePath))
  }

  function getArrPath() {
    if (media.type === "movie") {
      if (media.media.titleSlug === media.media.tmdbId.toString()) {
        return `/add/new?term=tmdbid:${media.media.titleSlug}`
      }
      return `/movie/${media.media.titleSlug}`
    } else if (media.type === "serie") {
      if (!media.media.seasonFolder) {
        return `/add/new?term=tvdb:${media.media.tvdbId.toString()}`
      }

      return `/series/${media.media.titleSlug}`
    }
  }

  function openArr(e: MouseEvent) {
    preventDispatch(e)

    const arrPath = getArrPath()

    if (!arrPath) {
      notify.warning("Impossible de trouve cette série ou ce film")
      return
    }

    appsNavigate(type === "movie" ? "radarr" : "sonarr", arrPath)
  }

  const alreadyRequested = useMemo(() => {
    return lastRequestedAt
      ? new Date(lastRequestedAt).getTime() > Date.now() - 1000 * 60 * 60 * 24
      : false
  }, [lastRequestedAt])

  const options = useMemo((): ContextMenuOption[] => {
    const options = [
      {
        label: `Ouvrir dans ${type === "movie" ? "Radarr" : "Sonarr"}`,
        onClick: openArr,
        icon: (type === "movie" ? "film" : "tv") as IconName,
      },
    ]

    if (filePath) {
      options.push({
        label: "Ouvrir dans les fichiers",
        onClick: openFiles,
        icon: "folder-open" as IconName,
      })
    }
    return options
  }, [role, filePath])

  const action = useMemo((): {
    text: string
    icon: IconName
    action: MouseEventHandler<HTMLElement> | null
    color: string | undefined
  } => {
    switch (status) {
      case "available":
      case "completed":
        return { icon: "play-circle", text: "Disponible", action: null, color: "green" }
      case "importPending":
      case "warning":
      case "failed":
      case "downloadClientUnavailable":
      case "importBlocked":
      case "failedPending":
      case "ignored":
        return {
          icon: "warning",
          text: "Il y a un problème",
          action: role <= RoleType.ADMIN ? openApp : null,
          color: "#b97a23",
        }
      case "unknown":
      case "notImported": {
        if (alreadyRequested) {
          return { icon: "clock", text: "Recherche...", action: null, color: "#546abc" }
        }
        return { icon: "download", text: "Importer", action: onImport, color: undefined }
      }
      case "watching": {
        if (alreadyRequested) {
          return { icon: "clock", text: "Recherche...", action: null, color: "#546abc" }
        }
        return { icon: "eye", text: "Surveillé", action: onRestartImport, color: "#546abc" }
      }
      case "delay":
      case "fallback":
      case "paused":
      case "queued":
      case "importing":
      case "imported":
      case "downloading":
      default:
        return {
          icon: "hourglass",
          text: "En cours d'import",
          action: null,
          color: "#546abc",
        }
    }
  }, [status, alreadyRequested])

  return (
    <div className={classes.container} style={style}>
      {downloadStatus?.download ? (
        <DownloadProgress download={downloadStatus.download} style={style} format={format} />
      ) : (
        <ButtonGroup className="flex row noWrap">
          <Button
            loading={isImporting}
            className={classes.importButton}
            icon={action.icon}
            iconProps={{ spin: action.icon === "clock" }}
            appearance="primary"
            style={{
              backgroundColor: action.color,
              cursor: action.action ? "pointer" : "default",
              pointerEvents: action.action ? "auto" : "none",
            }}
            onClick={action.action || undefined}
            disabled={isImporting}
            tooltip={format === "full" ? undefined : action.text}
          >
            {format === "full" ? action.text : null}
          </Button>
          {role <= RoleType.ADMIN ? (
            <ContextMenu placement="bottomEnd" options={options}>
              <Button icon="caret-down" className={classes.importButton} style={{ width: 20 }} />
            </ContextMenu>
          ) : null}
        </ButtonGroup>
      )}
    </div>
  )
}
