import { DiscoverType, MultiRes, Pagination } from "types/API"
import { Media, MediaType } from "types/Content"

import { ApiRequest } from "./request"

import { mediasApi } from "../medias"

export class Medias extends ApiRequest {
  async getRequests<T extends MediaType>(
    params: Partial<{ userId?: string; type?: T } & Pagination>,
  ): Promise<MultiRes<Media<T>>> {
    const response = await this.api.get<any, any>("/requests", { params })

    this.dispatch(mediasApi.actions.addList(response.results))
    return response as MultiRes<Media<T>>
  }

  async reImportAllRequests(): Promise<void> {
    await this.api.get("/request/reimport")
  }

  async retry(id: number): Promise<Media<any>> {
    const response = await this.api.get<void, Media<any>>(`/retry/${id}`)
    this.dispatch(mediasApi.actions.add(response))
    return response
  }

  async search<T extends MediaType>(
    params: Partial<{ type: T } & Pagination>,
  ): Promise<MultiRes<Media<T>>> {
    const response = await this.api.get<{ type: T } & Pagination, MultiRes<Media<T>>>("/search", {
      params,
    })
    this.dispatch(mediasApi.actions.addList(response.results))
    return response
  }

  async discover<T extends MediaType>(
    params: Partial<{ type: T } & Pagination> & {
      discoverType: DiscoverType<T>
    },
  ): Promise<MultiRes<Media<T>>> {
    const response = await this.api.get<{ type: T } & Pagination, MultiRes<Media<T>>>("/discover", {
      params,
    })
    this.dispatch(mediasApi.actions.addList(response.results))
    return response
  }

  async import<T extends "movie" | "serie">(media: Media<T>): Promise<Media<T>> {
    const response = await this.api.post<Media<T>, Media<T>>("/import", media)
    this.dispatch(mediasApi.actions.add(response))
    return response
  }

  async restartImport<T extends "movie" | "serie">(media: Media<T>) {
    const newMedia = await this.api.post<Media<T>, Media<T>>("/restartImport", {
      mediaId: media.type === "movie" ? media.media.tmdbId : media.media.tvdbId,
      type: media.type,
    })
    this.dispatch(mediasApi.actions.add(newMedia))
  }
}
