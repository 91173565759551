import { ReactNode, useMemo, useState } from "react"

import { Button } from "components/Button/Button"

import classes from "./Stepper.module.scss"

type StepperProps = {
  steps: {
    title: string
    subtitle?: string
    content: ReactNode
    prevButton?: (props: {
      index: number
      setIndex: React.Dispatch<React.SetStateAction<number>>
    }) => ReactNode
    nextButton?: (props: {
      index: number
      setIndex: React.Dispatch<React.SetStateAction<number>>
    }) => ReactNode
  }[]
  navigate?: "both" | "prev" | "next"
  onClickPrevious?: () => Promise<void>
  onClickNext?: () => Promise<void>
}

export function Stepper(props: StepperProps) {
  const { steps, navigate = "both", onClickPrevious, onClickNext } = props

  const [index, setIndex] = useState(0)

  const stepsTitles = useMemo(
    () =>
      steps.map((step, i) => (
        <div
          key={String(i)}
          className={classes.step}
          past-step={String(index > i)}
          current-step={String(index === i)}
          incoming-step={String(index < i)}
        >
          <div className={classes.stepIndex} incoming-step={String(index < i)}>
            {i + 1}
          </div>
          <div className={classes.stepTitle}>
            <h3>{step.title}</h3>
            {step.subtitle ? <h4>{step.subtitle}</h4> : null}
          </div>
        </div>
      )),
    [steps, index],
  )

  const stepsComponents = useMemo(
    () =>
      steps.map((step, i) => (
        <div key={String(i)} className={classes.stepComponentOverflow}>
          <div className={classes.stepComponent}>{step.content}</div>
        </div>
      )),
    [steps],
  )

  return (
    <div className={classes.stepper}>
      <div className={classes.stepperNav}>{stepsTitles}</div>
      <div className={classes.stepperContent}>
        <div className={classes.stepperComponents}>
          <div className={classes.stepperComponentNav} style={{ left: `-${index * 100}%` }}>
            {stepsComponents}
          </div>
        </div>
        <div className={classes.stepperNavigation}>
          {steps[index]?.prevButton ? (
            steps[index]?.prevButton({ index, setIndex })
          ) : (navigate === "both" || navigate === "prev") && index !== 0 ? (
            <Button
              appearance="primary"
              style={{ height: 25 }}
              disabled={index === 0}
              onClick={async () => {
                try {
                  await onClickPrevious?.()
                  setIndex(index - 1)
                } catch (error) {
                  // Do nothing
                }
              }}
            >
              Retour
            </Button>
          ) : (
            <div />
          )}
          {steps[index]?.nextButton ? (
            steps[index]?.nextButton({ index, setIndex })
          ) : (navigate === "both" || navigate === "next") && index !== steps.length - 1 ? (
            <Button
              appearance="primary"
              style={{ height: 25 }}
              onClick={async () => {
                try {
                  await onClickNext?.()
                  setIndex(index + 1)
                } catch (error) {
                  // Do nothing
                }
              }}
            >
              Continuer
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}
