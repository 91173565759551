import { IconName } from "@fortawesome/fontawesome-svg-core"
import { forwardRef, MouseEvent, useRef } from "react"
import { Popover, PopoverProps, Whisper } from "rsuite"
import {
  OverlayTriggerHandle,
  OverlayTriggerProps,
} from "rsuite/esm/internals/Overlay/OverlayTrigger"

import { FontIcon } from "components/FontIcon/FontIcon"

import classes from "./ContextMenu.module.scss"

export type ContextMenuOption = {
  icon?: IconName
  label: string
  onClick: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

type ContextMenuProps = {
  children?: React.ReactNode
  options: ContextMenuOption[]
  trigger?: OverlayTriggerProps["trigger"]
  placement?: OverlayTriggerProps["placement"]
} & Partial<PopoverProps>

export const ContextMenu = forwardRef((props: ContextMenuProps, ref) => {
  const { trigger = "click", placement = "bottom", options, children, ...popoverProps } = props

  const whisperRef = useRef<OverlayTriggerHandle | null>()

  if (!options.length) return null

  return (
    <Whisper
      trigger={trigger}
      placement={placement}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }}
      controlId="importButton-context-menu"
      speaker={
        <Popover ref={ref} {...popoverProps}>
          <div className={classes.menuContainer}>
            {options.map((option, i) => {
              return (
                <div
                  key={i}
                  className={classes.option}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                    option.onClick(e)
                    whisperRef.current?.close()
                  }}
                >
                  {option.icon && <FontIcon icon={option.icon} className="marginRight8" />}
                  {option.label}
                </div>
              )
            })}
          </div>
        </Popover>
      }
      ref={(ref) => {
        whisperRef.current = ref
      }}
    >
      {children}
    </Whisper>
  )
})
