import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query/react"

import api from "api"
import auth from "api/auth"
import medias from "api/medias"

const store = configureStore({
  reducer: {
    [api.invitations.reducerPath]: api.invitations.reducer,
    [api.users.reducerPath]: api.users.reducer,
    auth,
    medias,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.invitations.middleware, api.users.middleware),
})

setupListeners(store.dispatch)

export const dispatch = store?.dispatch || (() => undefined)

export type RootState = ReturnType<typeof store.getState>

export { store }
