import { useMemo } from "react"

import { notify } from "components/Notification/Notification"

export function useErrorsHandling() {
  const errorStatus = useMemo(() => {
    return new URLSearchParams(window.location.search).get("error")
  }, [window.location.search])

  useMemo(() => {
    const redirectUrl = new URLSearchParams(window.location.search).get("return")
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }, [window.location.search])

  if (errorStatus && !isNaN(Number(errorStatus))) {
    switch (Number(errorStatus)) {
      case 400: {
        notify.error("Une erreur de requête à été détectée.", `400 Bad Request`)
        break
      }
      case 401: {
        notify.error("Vous n'êtes pas autorisé a accéder à ce contenu.", `401 Unauthorized`)
        break
      }
      case 402: {
        notify.error("Nous avons rencontré une erreur.", `402 Payment Required Experimental`)
        break
      }
      case 403: {
        notify.error("Vous n'êtes pas autorisé a accéder à ce contenu.", `403 Forbidden`)
        break
      }
      case 404: {
        notify.error("La page demandée est introuvable.", `404 Not Found`)
        break
      }
      case 405: {
        notify.error("Une erreur de requête à été détectée.", `405 Method Not Allowed`)
        break
      }
      case 408: {
        notify.error("La requête a expirée.", `408 Request Timeout`)
        break
      }
      case 500: {
        notify.error("Nous avons rencontré une erreur.", `500 Internal Server Error`)
        break
      }
      case 501: {
        notify.error("Nous avons rencontré une erreur.", `501 Not Implemented`)
        break
      }
      case 502: {
        notify.error("Nous avons rencontré une erreur.", `502 Bad Gateway`)
        break
      }
      case 503: {
        notify.error("Nous avons rencontré une erreur.", `503 Service Unavailable`)
        break
      }
      case 504: {
        notify.error("Nous avons rencontré une erreur.", `504 Gateway Timeout`)
        break
      }
      case 505: {
        notify.error("Nous avons rencontré une erreur.", `505 HTTP Version Not Supported`)
        break
      }
      case 506: {
        notify.error("Nous avons rencontré une erreur.", `506 Variant Also Negotiates`)
        break
      }
      case 507: {
        notify.error("Nous avons rencontré une erreur.", `507 Insufficient Storage`)
        break
      }
    }
  }
}
