import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import request from "api/request"
import { RootState } from "services/store"
import { plexAuth } from "views/PlexAuth/authUtils"

export function useAuthenticate() {
  const { me, token } = useSelector((state: RootState) => state.auth)

  const [isLogging, setIsLogging] = useState(false)
  const [isAuthenticate, setIsAuthenticate] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  async function logout() {
    await request.auth.logout()
  }

  useEffect(() => {
    if (token) {
      setIsLogging(true)
      request.auth
        .getMe()
        .catch(() => {
          request.auth.logout()
          setIsLoggedIn(false)
        })
        .then(() => setIsLoggedIn(true))
        .finally(() => setIsLogging(false))
    }
  }, [])

  async function authenticate() {
    setIsAuthenticate(true)
    try {
      const plexAuthToken = await plexAuth(() => setIsAuthenticate(false))
      await request.auth.login(plexAuthToken)
      await request.auth.getMe()
    } catch (error) {
      console.error(error)
    } finally {
      setIsAuthenticate(false)
    }
  }

  return {
    isLoggedIn,
    isLogging,
    isAuthenticate,
    authenticate,
    logout,
    me,
  }
}
