import { createContext, useEffect, useState } from "react"

import request from "api/request"
import { PlayerLink, Server } from "types/Server"

export type ServerContextType = Server & { librariesLink: PlayerLink[] }

const defaultState = {
  libraries: [],
  machineIdentifier: "",
  librariesLink: [],
}

export const ServerContext = createContext<ServerContextType>(defaultState)

export function ServerContextProvider(props: { children: React.ReactNode }) {
  const { children } = props

  const [server, setServer] = useState<Server>(defaultState)
  const [librariesLink, setLibraryLinks] = useState<PlayerLink[]>([])

  useEffect(() => {
    request.server.getServerInfos().then(setServer)
  }, [])

  useEffect(() => {
    if (server) {
      setLibraryLinks(
        server.libraries.map((library) => ({
          link: `media/${server.machineIdentifier}/com.plexapp.plugins.library?source=${library.key}`,
          name: library.title,
          icon: library.type === "movie" ? "film" : "tv",
        })),
      )
    }
  }, [server])

  return (
    <ServerContext.Provider value={{ ...server, librariesLink }}>{children}</ServerContext.Provider>
  )
}
