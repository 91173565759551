import moment from "moment"
import { useContext } from "react"

import { ImportButton } from "components/Content/ImportButton/ImportButton"
import { Field } from "components/Field/Field"
import { MultiRating } from "components/Rating/MultiRating/MultiRating"
import { DownloadContext } from "contexts/DownloadContext"
import { Media } from "types/Content"
import { getTMDBImageLink } from "utils/tmdb"

import classes from "./Overview.module.scss"

type SerieOverviewProps = {
  serie: Media<"serie">
}

export function SerieOverview(props: SerieOverviewProps) {
  const { serie } = props

  const { openMediaDetail } = useContext(DownloadContext)

  const { poster_path, overview, first_air_date } = serie.tmdbMedia || {}

  if (!serie.tmdbMedia) return null

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: `url('${getTMDBImageLink(poster_path)}')`,
      }}
    >
      <div className={classes.background} onClick={() => openMediaDetail(serie)}>
        <div className="flex fullWidth end">
          <ImportButton media={serie} format="compact" />
        </div>
        <div className="flex column fullWidth">
          <Field
            name="Date de sortie"
            icon={moment(first_air_date).isBefore() ? "calendar" : "bullhorn"}
            value={moment(first_air_date).format("LL")}
            className={classes.field}
          />
          <Field
            name="Genre"
            icon="tag"
            value={
              <div className={classes.genre}>
                {serie.tmdbMedia.genres?.map((genre) => genre.name).join(", ")}
              </div>
            }
            className={classes.field}
          />
          <Field
            name="Synopsis"
            icon="circle-info"
            value={<div className={classes.overview}>{overview || serie?.media?.overview}</div>}
            className={classes.field}
            iconStyle={{
              marginRight: 0,
            }}
          />
        </div>
        <div className="flex center">
          <MultiRating ratings={{ tmdb: { value: serie.media.ratings.value } } as any} />
        </div>
      </div>
    </div>
  )
}
