import { ReactNode } from "react"
import { useSelector } from "react-redux"

import { RoleType } from "roles"
import { RootState } from "services/store"

type RoleRestrictProps = {
  children?: ReactNode
  role?: number
}

export function RoleRestrict(props: RoleRestrictProps) {
  const { children, role = RoleType.ADMIN } = props

  const me = useSelector((state: RootState) => state.auth.me)

  if (!me || me.role > role) {
    return null
  }

  return children
}
